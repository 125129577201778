:root {
  --topHeader1Background: #03045e;
  --topHeader1Filter: brightness(0) saturate(100%) invert(96%) sepia(1%) saturate(1505%) hue-rotate(63deg)
    brightness(103%) contrast(96%);

  --topHeader2Background: #023e8a;
  --topHeader2Filter: brightness(0) saturate(100%) invert(35%) sepia(71%) saturate(6247%) hue-rotate(341deg)
    brightness(99%) contrast(82%);

  --primaryColor: #013160;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../fonts/Poppins-ExtraBold.ttf");
}
body {
  /* font-family: "Poppins-SemiBold" !important; */
  font-family: "Poppins-Regular" !important;
}
.row {
  margin: 0 !important;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeRight {
  animation: fadeRight 1s ease-out forwards;
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeLeft {
  animation: fadeLeft 1s ease-out forwards;
}

@keyframes fadeTop {
  0% {
    opacity: 0;
    transform: translateY(-100px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
  }
}

.fadeTop {
  animation: fadeTop 1s ease-out forwards;
}

@keyframes heartbeat {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(1);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.3);
  }
}
.heartbeat {
  animation: heartbeat 5s ease-in-out infinite;
}
.navbar-toggler-icon:hover {
  filter: brightness(0) saturate(100%) invert(15%) sepia(75%) saturate(1468%) hue-rotate(188deg) brightness(93%)
    contrast(105%);
}

.navbar-toggler:focus {
  box-shadow: none !important;
}
.swiper-button-prev::after {
  font-size: 24px !important;
  color: #000 !important;
}
.swiper-button-next::after {
  font-size: 24px !important;
  color: #000 !important;
}
/* .top-header {
   background-color: var(--topHeader1Background);
  color: #caf0f8 !important; 
  font-weight: 600;
  padding: 1rem;
} */

.top-header .top-header-phone {
  filter: brightness(0) saturate(100%) invert(29%) sepia(16%) saturate(10%) hue-rotate(315deg) brightness(86%)
    contrast(95%);
}

.top-header .top-header-mail {
  filter: brightness(0) saturate(100%) invert(29%) sepia(16%) saturate(10%) hue-rotate(315deg) brightness(86%)
    contrast(95%);
}

/* .top-footer {
  background-color: var(--topHeader2Background);
  padding: 1rem !important;
} */
.top-footer-sphere {
  filter: brightness(0) saturate(100%) invert(15%) sepia(75%) saturate(1468%) hue-rotate(188deg) brightness(93%)
    contrast(105%);
}

.footerIconSvg {
  filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(7352%) hue-rotate(178deg) brightness(131%)
    contrast(100%);
}
/* .top-footer .top-footer-title {
  font-family: "Poppins-Bold" !important;
  font-weight: 600;
  font-size: 28px;
  color: #777 !important;
} */
.top-footer .nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  flex-grow: 0;
}
.top-footer .nav-menu-item {
  font-family: "Poppins-Bold" !important;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-right: 2rem;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}
.nav-inActive {
  color: #caf0f8 !important;
}
.top-footer .nav-menu-item:hover,
.nav-active {
  color: var(--topHeader1Background) !important;
  border-bottom: 1px #e63946;
  border-bottom-style: outset;
}
.footer {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
  padding: 1rem;
  bottom: 0;
}
.btn-close:focus {
  box-shadow: none !important;
}

.table-transistion {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out !important;
}

/* .table-transistion {
  transform: translate(0, 0) !important;
  opacity: 1 !important;
} */
/* .primaryBtn {
  background-color: #03045e !important;
  border: none !important;
  width: 200px;
  padding: 0.8rem !important;
  font-weight: 600 !important;
} */
.secondaryBtn {
  background-color: #caf0f8 !important;
  width: 200px;
  padding: 0.8rem !important;
  border: 1px solid #03045e !important;
  color: #03045e !important;
  font-weight: 600 !important;
}

.secondaryBtn:hover {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
/* .firstSection > .swiper {
  width: 100%;
  height: 500px;
} */

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* margin-top: 1rem; */
  /* padding: 40px 60px; */
}
.mySwiperSecond {
  height: 100% !important;
}
.serviceSwiperSlide {
  font-size: initial !important;
  box-sizing: initial !important;
  padding: initial !important;
}

.overlay-caption {
  opacity: 0;
  /* visibility: hidden; */
}

.gallery-container:hover {
  opacity: 1;
  /* visibility: visible; */
}
.primaryBtn {
  font-family: "Poppins-SemiBold" !important;
  width: fit-content;
  border-radius: 0 !important;
  padding: 1rem 1.5rem !important;
  background-color: var(--primaryColor) !important;
  border-style: none !important;
  letter-spacing: 1.5px !important;
  text-transform: uppercase;
  font-size: 12px !important;
}
.primaryBtn:hover {
  background-color: #15568a !important;
}
.lightBtn {
  font-family: "Poppins-SemiBold" !important;
  width: fit-content;
  border-radius: 0 !important;
  padding: 1rem 1.5rem !important;
  background-color: #fff !important;
  border: 1px solid #e7e7e7 !important;
  color: #000 !important;
  letter-spacing: 1.5px !important;
  text-transform: uppercase;
  font-size: 12px !important;
  margin-right: 1.5rem;
}
.lightBtn:hover {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
  border-color: var(--primaryColor) !important;
}

@media only screen and (max-width: 1023px) {
  .lightBtn {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
