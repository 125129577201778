.aboutCompanyContainer {
  display: flex;
  margin-top: 2rem;
}
.companyImg {
  width: 400px;
  height: 300px;
  margin-right: 2rem;
  object-fit: cover;
}
.aboutCompanyContainer > div > p {
  color: #777 !important;
  font-size: 16px;
  font-family: "Poppins-Regular" !important;
  letter-spacing: 0.03rem;
  text-align: justify;
}
.aboutTeamContainer {
  margin-top: 2rem;
  background-color: #212529;
  color: #fff;
  padding: 2rem;
}

.aboutTeamContainer > h3 {
  text-align: center;
  font-family: "Poppins-Bold" !important;
  margin-bottom: 2rem;
}
.aboutTeamContainer > p {
  text-align: center;
  font-family: "Poppins-Bold" !important;
  margin: 3rem 0;
}
.aboutTeamDetails {
  display: flex;
  justify-content: space-between;
}
.aboutTeamCard {
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
}
.teamImg {
  width: 100%;
  height: 300px;
  object-fit: contain;
  background-color: #77777700;
}
.aboutTeamCard > h3 {
  margin-top: 2rem;
  text-align: center;
  font-family: "Poppins-Bold" !important;
}

.aboutTeamCard > h5 {
  text-align: center;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
  font-size: 14px;
  color: #777;
}

.aboutTeamCard > p {
  margin-top: 0.5rem;
  font-size: 14px;
  color: #777;
  font-family: "Poppins-Regular" !important;
  letter-spacing: 0.03rem;
  text-align: justify;
}
.lastContentContainer > p {
  margin: 2rem 0;
  text-align: center;
  font-family: "Poppins-SemiBold" !important;
  color: #212529;
}

@media only screen and (max-width: 1023px) {
  .aboutCompanyContainer {
    margin-top: 0.5rem;
    flex-direction: column;
  }
  .companyImg {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .aboutTeamDetails {
    flex-direction: column;
  }
  .teamImg {
    height: 300px;
    object-fit: cover;
  }
}
