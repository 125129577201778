.contactSection {
  background-color: #212529;
}
.contactSection > .contactContainer {
  display: flex;
  justify-content: flex-start;
  padding: 6rem 0;
  flex-grow: 1;
}
.contactSection > .contactContainer > .contactFirstHalf {
  flex-basis: 50%;
  border-right: 1px solid #969696;
  padding-right: 0.5rem;
}
.contactSection > .contactContainer > div > h5 {
  color: #fff !important;
  font-family: "Poppins-Bold" !important;
}
.contactSection > .contactContainer > div > h2 {
  color: #fff !important;
  font-family: "Poppins-Bold" !important;
}

.contactSection > .contactContainer > div > p {
  padding: 1rem 0;
  color: #969696 !important;
  font-family: "Poppins-Regular" !important;
}
.contactSection > .contactContainer > .contactSecondHalf {
  margin-left: 3rem;
}
.contactSection > .contactContainer > .contactSecondHalf > span > span {
  font-size: 20px;
}

.contactSection > .contactContainer > div {
  justify-content: space-evenly;
  color: #fff !important;
  font-family: "Poppins-Bold" !important;
}
.phoneImg,
.mailImg,
.locationImg {
  filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(7352%) hue-rotate(178deg) brightness(131%)
    contrast(100%);
  width: 25px;
}
.contactCallSection {
  padding: 1rem;
  background-color: var(--primaryColor);
}
/* .contactCallContainer {
  padding: 2rem 0;
} */
.contactCallSection > .contactCallContainer > div > h5 {
  color: #fff !important;
  font-family: "Poppins-Bold" !important;
}
.contactCallSection > .contactCallContainer > div > h2 {
  color: #fff !important;
  font-family: "Poppins-Bold" !important;
  margin-bottom: 2rem;
}
.contactCallSection > .contactCallContainer > div > p {
  color: #969696 !important;
  /* margin-bottom: 2rem; */
}
.contactCallInputField {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
  padding: 1rem 0;
  border: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
  border-radius: 0 !important;
  margin-top: 0.5rem;
  font-family: "Poppins-SemiBold" !important;
  font-size: 0.85rem !important;
}
.contactCallInputField::placeholder {
  font-family: "Poppins-SemiBold" !important;
  color: #fff !important;
  font-size: 0.85rem !important;
}
.contactCallInputField:focus {
  box-shadow: none !important;
}
.contactCallSection > .contactCallContainer > div > Button {
  margin-top: 1rem;
}
.contactCallSection > .contactCallContainer > div > .formError {
  color: #ff031ec9 !important;
  font-weight: 600;
  letter-spacing: 0.025rem;
  font-size: 12px;
}
@media only screen and (max-width: 1023px) {
  .contactSection > .contactContainer {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2rem;
    flex-grow: 1;
  }
  .contactSection > .contactContainer > div {
    margin-bottom: 1rem;
  }
  .contactSection > .contactContainer > .contactFirstHalf {
    flex-basis: 100%;
    border-right: none;
  }
  .contactSection > .contactContainer > div > span {
    margin-bottom: 1rem;
  }
  .contactSection > .contactContainer > .contactSecondHalf {
    margin-left: 0rem;
  }
  .phoneImg,
  .mailImg,
  .locationImg {
    width: 18px;
  }
  .contactSection > .contactContainer > .contactSecondHalf > span > span {
    font-size: 16px;
  }
}
