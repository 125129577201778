/* .mySwiperFirstSection {
  width: 100%;
  height: 500px;
  object-fit: cover;
} */
/* .swiperImg {
  filter: brightness(0.5);
} */

/* .swiper {
  width: 100%;
  height: 500px;
  background: #000;
} */

/* .swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
} */

.parallaxBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

/* .swiperTitle {
  font-size: 41px;
  font-weight: 300;
} */

.swiperSubtitle {
  font-size: 21px;
}

.secondLayer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}
.secondLayerImg {
  width: 350px;
  height: 350px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.servicesSwiperImg {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}

.boxContent {
  font-family: "Poppins-SemiBold" !important;

  background-color: #013160;
  color: #fff;
  padding: 1rem;
  font-size: 14px !important;
  letter-spacing: 0.03rem;
}
.sectionThird {
  background-color: #caf0f8;
  padding: 2rem;
}
.sectionFourth {
  background-color: #fff;
  padding: 2rem;
}
.sectionThird .section {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.sectionThirdImg {
  width: 300px;
  height: 300px;
  object-fit: cover;

  /* background-image: url("../../assets/images/png&jpg/businessGuy.jpg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  /* filter: brightness(0.8); */
  background-attachment: fixed !important;
  /* position: relative; */
}

.sectionThird .title {
  color: #023e8a;
  font-size: 16px;
  font-weight: 600;
  /* text-align: center; */
}
.sectionFourth {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/images/png&jpg/motherBoardDark.png");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important; */
  /* filter: brightness(0.8); */
  /* background-attachment: fixed !important; */
  /* position: relative; */
  background-color: #212529;
  color: #fff;
}
.sectionFourth > .imageBanner {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
}
.sectionFourth > .imageBanner > .container > .title {
  font-family: "Poppins-Bold" !important;
  /* text-shadow: 7px -8px 8px rgba(177, 173, 179, 1); */
  /* background-color: rgba(254, 173, 255, 0.1); */
  color: rgba(255, 255, 255, 1);
  /* font-size: 42px; */
  margin-bottom: 2rem;
}
.sectionFourth .details {
  /* margin-top: 2rem; */
  color: #969696;
  font-size: 14px;
  letter-spacing: 0.03rem;
  text-align: justify;
}
.sectionFourth .keyPoints {
  color: var(--topHeader1Background);
  font-weight: 600;
  background-color: rgb(217 217 244 / 42%);
}
.curveImg {
  background-image: url("../../assets/images/svg/waves.svg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  filter: brightness(0) saturate(100%) invert(27%) sepia(79%) saturate(2121%) hue-rotate(180deg) brightness(92%)
    contrast(102%);
  right: 0;
  position: absolute;
  height: 100px;
  width: 100%;
  bottom: 0;
}
.sectionFiveth {
  background-image: url("../../assets/images/png&jpg/motherBoard.jpg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-blend-mode: saturation;
  /* background-color: #013160 !important; */
  background-color: #013160bf !important;
  text-align: center;
  padding: 2rem;
  text-transform: capitalize !important;
  color: #fff !important;
  padding: 4rem 0 !important;
  /* background-color: #94bfe842;
  background-blend-mode: exclusion; */
}

.sectionFiveth > .title {
  /* text-shadow: 3px 3px 4px black; */
  font-weight: 700;
  font-size: 50px;
  line-height: 1.2em;
  color: #fff;
  margin-bottom: 1.5rem;
}
.sectionFiveth > .btn {
  display: flex;
  justify-content: center;
}
.curveImgBottom {
  position: relative !important;
  transform: rotateX(180deg);
}

.sectionSixth > .containerSixth > .title {
  /*
  text-shadow: 3px 3px 4px #caf0f8;
  font-weight: 700;
  font-size: 50px;
  color: #03045e;
  margin-bottom: 1.5rem; */

  margin-top: 3rem;
  font-weight: 700;
  width: 100%;
  opacity: 0.09 !important;
  color: #03045e;
  /* -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0); */
  white-space: nowrap;
  font-size: 100px;
  /* display: block; */
  margin-bottom: 2.5rem;
}

.swiperContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.swiperImgTop {
  width: 100%;
  height: 600px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  filter: brightness(0.9);
  background-blend-mode: multiply;
  background-color: #01316075 !important;
}

.img1 > .swiperContainer {
  align-items: start;
}
.img2 > .swiperContainer {
  align-items: end;
}
.swiperTitle {
  font-family: "Poppins-Bold" !important;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  /* text-shadow: 3px 3px 4px #013160; */
}

.swiperText {
  font-size: 16px;
  max-width: 650px;
  margin-top: 2rem;
  /* text-shadow: 7px -8px 8px #013160; */
  font-family: "Poppins-Regular" !important;
  color: #e2e2ff;
  letter-spacing: 0.05rem;
  line-height: 1.7rem;
}

.img1 > .swiperContainer > .swiperText {
  text-align: start;
  background-color: #06122ac9;
}
.img2 > .swiperContainer > .swiperText {
  text-align: end;
  background-color: #131a1d73;
}
.sectionCommitment {
  display: flex;
}
.sectionCommitment > .imgContainer {
  background-image: url("../../assets/images/png&jpg/planning.jpg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  /* filter: brightness(0.8); */
  background-attachment: fixed !important;
  position: relative;
  /* height: 350px; */
  /* background-color: #94bfe842;
  background-blend-mode: exclusion; */
}
.sectionCommitment > .textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}
.sectionCommitment > .textContainer > .heading {
  font-family: "Poppins-Bold" !important;
  margin-bottom: 2rem;
}
.sectionCommitment > .textContainer > .points {
  color: #777 !important;
  letter-spacing: 0.03rem;
  text-align: justify;
  font-size: 14px;
}

.sectionTwo {
  background-color: #f4f4f4 !important;
}
.sectionTwo > .container {
  padding: 2rem 0;
}
.sectionTwo > .container > span > h1,
.sectionTwo > .container > div > h1 {
  font-family: "Poppins-SemiBold" !important;
}
.sectionTwo > .container > span > p {
  color: #777 !important;
  letter-spacing: 0.03rem;
}
.blogContainer {
  display: flex;
  flex-direction: column;
}
.blogContainer > .date {
  width: fit-content;
  padding: 0.2rem 1.2rem;
  background-color: #013160;
  color: #fff;
  text-align: center;
}
.blogContainer > .date > p {
  margin-bottom: 0 !important;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.3rem;
}
.blogContainer > .date > span {
  letter-spacing: 0.08rem;
  font-size: 12px !important;
}
.blogContainer > .blogUser {
  font-size: 14px !important;
  text-transform: uppercase;
  color: #777;
  margin-bottom: 0 !important;
}
.blogContainer > .blogTitle {
  font-family: "Poppins-Bold" !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  height: 70px;
  max-height: 4.5em;
  line-height: 1.5em;
}
.blogContainer > .blogPoints {
  color: #777;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  height: 80px;
  max-height: 4.5em; /* Limit height based on line height */
  line-height: 1.5em; /* Set the line height (1.5em is typical) */
  letter-spacing: 0.03rem;
}
.blogContainer > .viewMore {
  color: #013160;
  font-size: 14px !important;
  cursor: pointer;
}




@media only screen and (max-width: 767px) {
  .sectionCommitment > .imgContainer {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .swiperImgTop {
    height: 400px;
  }
  .swiperImgTop > .swiperContainer > .swiperTitle {
    font-size: 28px;
  }
  .swiperImgTop > .swiperContainer > .swiperText {
    font-size: 12px;
  }

  .sectionTwo > .container > span > p {
    text-align: justify;
  }

  .sectionTwo > .container {
    padding: 2rem;
  }
  .sectionCommitment > .imgContainer {
    height: 500px;
  }
  .sectionFiveth > .title {
    font-size: 32px;
  }
  .sectionFiveth > h4 {
    font-size: 16px !important;
  }
  .sectionFiveth > p {
    font-size: 12px !important;
  }
  .sectionFiveth > .btn {
    flex-direction: column;
    align-items: center;
  }
  .sectionSixth > .containerSixth > .title {
    font-size: 50px;
  }
}
.viewMore:hover {
  width: fit-content;
  color: var(--topHeader1Background) !important;
  border-bottom: 1px #e63946;
  border-bottom-style: outset;
}

