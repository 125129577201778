/* .serviceBody {
  background-color: #f4f4f4 !important;
} */

.headerTitle {
  font-family: "Poppins-Bold" !important;
  color: var(--primaryColor);
  text-align: center;
  margin: 2rem;
}
.serviceContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.serviceRow {
  display: flex;
  margin-bottom: 3rem;
  /* align-items: center; */
  flex-direction: column;
  flex: 0 0 30%;
}
.serviceRow > .serviceImg {
  /* width: 250px; */
  width: 100%;
  height: 200px;
  margin-right: 2rem;
  object-fit: cover;
}
.serviceRow > .serviceDetails {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.serviceRow > .serviceDetails > div > h3 {
  font-family: "Poppins-Bold" !important;
  font-size: 18px !important;
  margin: 1rem 0;
  height: 30px;
  max-height: 70px;
}
.serviceRow > .serviceDetails > div > span {
  font-size: 16px !important;
  font-family: "Poppins-SemiBold" !important;
  color: var(--primaryColor);
  margin-bottom: 1rem;
  line-height: 1.8rem;
  height: 150px;
  max-height: 200px;
}
.serviceRow > .serviceDetails > div > p {
  font-size: 16px !important;
  color: #777;
  margin-bottom: 1rem;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* height: 150px;
  max-height: 200px; */
  letter-spacing: 0.03rem;
}

.serviceRow > div > .price {
  font-size: 16px !important;
  /* color: #777; */
  margin-bottom: 0.5rem !important;
}
.serviceRow > div > button {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.internetSection > .serviceContainer > .serviceRow > div > button {
  position: relative;
}
.onSiteSection {
  background-color: #212529;
  color: #fff;
  /* text-align: center; */
  text-transform: capitalize !important;
  padding: 2rem 0 !important;
}
.onSiteSection > .container {
  display: flex;
  align-items: center;
}
.onSiteImg {
  width: 400px;
  /* height: 350px; */
  margin-right: 3rem;
}
.onSiteSection > .container > div > .title {
  /* text-shadow: 3px 3px 4px black; */
  font-weight: 700;
  font-size: 50px;
  line-height: 1.2em;
  color: #fff;
  margin-bottom: 1.5rem;
}
.onSiteSection > .container > div > div {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.03rem;
  line-height: 1.5rem;
}
.internetSection {
  margin: 3rem 0;
}
.annualSection {
  background-color: #f4f4f4;
  padding: 3rem 0;
}
.annualSection > .container > h3 {
  font-family: "Poppins-Bold" !important;
  font-size: 24px !important;
  text-align: center;
}
.annualSection > .container > .containerSection {
  display: flex;
  justify-content: space-evenly;
}
.annualSection > .container > .row {
  display: flex;
  flex-direction: column;
}
.sectionCard > span > h5 {
  font-family: "Poppins-SemiBold" !important;
  font-size: 18px !important;
  margin-bottom: 0 !important;
  color: var(--primaryColor);
}
.sectionCard > span > img {
  filter: brightness(0) saturate(100%) invert(15%) sepia(75%) saturate(1468%) hue-rotate(188deg) brightness(93%)
    contrast(105%);
  width: 18px;
  height: 18px;
}

.sectionCommitment {
  display: flex;
}
.sectionCommitment > .imgContainer {
  background-image: url("../../assets/images/png&jpg/checkingCpu.webp");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  /* filter: brightness(0.8); */
  background-attachment: fixed !important;
  position: relative;
  /* height: 350px; */
}
.sectionCommitment > .textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}
.sectionCommitment > .textContainer > .heading {
  font-family: "Poppins-Bold" !important;
  margin-bottom: 1rem;
}

.sectionCommitment > .textContainer > .title {
  font-family: "Poppins-SemiBold" !important;
  color: var(--primaryColor);
  margin-bottom: 1rem;
}
.sectionCommitment > .textContainer > .points {
  color: #777 !important;
  letter-spacing: 0.03rem;
}
.sectionCommitment > .textContainer > .keyPoints {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 14px !important;
  letter-spacing: 0.03rem;
}
.repairSection {
  display: flex;
  padding: 3rem 0;
  align-items: center;
  justify-content: space-evenly;
}
.repairSection > div > img {
  width: 200px;
  height: 200px;
  margin-right: 2rem;
  display: inline-block;
  /* animation: bounce 3s infinite; */
  animation: smoothBounce 2s infinite;
}

.repairSection > div > div > h3 {
  font-family: "Poppins-SemiBold" !important;
  font-size: 50px !important;
}

.repairSection > div > div > ul > li {
  font-family: "Poppins-Regular" !important;
  font-size: 16px !important;
  line-height: 2.2em;
  margin-left: 1rem;
}
.repairSection > .contactBtn {
  display: flex;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.smooth-bounce {
  animation: smoothBounce 2s infinite;
  display: inline-block;
}

@keyframes smoothBounce {
  0%,
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); /* Eases into and out of the bounce */
  }
  50% {
    transform: translateY(-30px); /* Move up by 30px */
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); /* Quick bounce up */
  }
}
.modalContainer {
  display: flex;
}
.modalContainer > .serviceImg {
  width: 50%;
  /* height: 350px; */
  object-fit: cover;
  margin-right: 1rem;
}
.modalCustomTitle {
  font-family: "Poppins-Bold" !important;
  font-size: 18px;
}
.modalFooter {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 1rem 0;
}
.serviceCardDetails > span {
  font-family: "Poppins-SemiBold" !important;
  color: var(--primaryColor);
  /* padding-bottom: 2rem; */
}
.serviceCardDetails > p {
  margin-top: 0.3rem;
  color: #777 !important;
  margin-bottom: 0.5rem;
  font-size: 14px;
  letter-spacing: 0.03rem;
}
.serviceCardDetails > .price {
  color: #000000 !important;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1023px) {
  .serviceContainer {
    flex-direction: column;
  }
  .serviceRow {
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .serviceRow > .serviceImg {
    width: 100%;
    height: 200px;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .serviceRow > .serviceDetails {
    flex-direction: column;
    align-items: flex-start;
  }
  .serviceRow > div > h3 {
    font-size: 18px !important;
  }
  .serviceRow > div > span {
    font-size: 16px !important;
  }
  .serviceRow > div > p {
    font-size: 14px !important;
  }
  .annualSection > .container > .containerSection {
    flex-direction: column;
    align-items: baseline;
  }
  .sectionCard > span > h5 {
    font-size: 16px !important;
  }
  .sectionCard > span > img {
    width: 15px;
    height: 15px;
  }

  .onSiteSection > .container {
    flex-direction: column;
  }
  .onSiteImg {
    width: 100%;
    /* height: 300px; */
    margin-right: 0;
  }
  .onSiteSection > .container > div > .title {
    font-size: 38px;
    margin-top: 1rem;
  }
  .onSiteSection > .container > div > h4 {
    font-size: 18px;
  }
  .onSiteSection > .container > div > div {
    font-size: 14px;
  }
  .repairSection {
    flex-direction: column;
    align-items: center;
  }
  .repairSection > div > div > h3 {
    font-size: 38px !important;
  }
  .repairSection > div > img {
    display: none;
  }
  .repairSection > .contactBtn {
    flex-direction: column;
  }
  .repairSection > .contactBtn > Button {
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  .modalContainer {
    flex-direction: column;
  }
  .modalContainer > .serviceImg {
    width: 100%;
    object-fit: cover;
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
}
