.headerTop {
  transition: ease height 300ms;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  background-color: #fff;
  color: #444;
  font-weight: 600;
  padding: 0.8rem;
}
.headerBottom {
  background-color: #fff;
  color: #212529;
  /* padding: 1rem; */
  /* box-shadow: 0 0 3px rgba(234, 234, 234, 0.5); */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding-bottom: 0;
}
.headerBottomTitle {
  font-family: "Poppins-ExtraBold" !important;
  font-size: 22px;
  font-weight: 800;
  color: #013160;
  /* letter-spacing: 0.05rem; */
}
.navMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
}
.navItem {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  margin-right: 2rem;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.navActiveItem {
  color: #013160;
  border-bottom: 1px solid red;
}
.navInActiveItem {
  color: #444;
}

/*---Footer Css---*/

.sectionFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem 0;
}
.sectionCol1 {
  display: flex;
  flex-direction: column;
  width: 350px;
}
.sectionCol {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.sectionCol1 > h5,
.sectionCol > h5 {
  font-family: "Poppins-Bold" !important;
  margin-bottom: 1.5rem;
  font-weight: 600;
}
.sectionCol1 > p,
.sectionCol > p {
  font-size: 14px;
  cursor: pointer;
}
.lastSectionFooter {
  border-top: 1px outset #caf0f8;
  text-align: center;
  padding: 1rem;
}
.breadcrumbBanner {
  background-image: url("../../assets/images/png&jpg/redFireChip.jpg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 350px;
  filter: brightness(0.8);
  background-blend-mode: multiply;
  background-color: #01316075 !important;
}
.customContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.breadcrumbBanner > .customContainer > div > .title {
  font-family: "Poppins-Bold" !important;
  color: #fff;
  font-size: 34px;
  text-shadow: 3px 3px 4px #013160;
}
.breadcrumbBanner > .customContainer > div > .path {
  font-family: "Poppins-Regular" !important;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}
.comingSoon {
  font-family: "Poppins-SemiBold" !important;
  padding-top: 0.5rem;
  text-align: center;
  background-color: #212529;
  color: #fff;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .headerTop {
    display: none;
  }
  .navMenu {
    margin-top: 1rem;
    flex-direction: column;
    align-items: start;
  }
  .navMenu > .navItem {
    margin-bottom: 0.5rem;
  }
  .sectionFooter {
    flex-direction: column;
  }
  .breadcrumbBanner {
    height: 150px;
  }
  .breadcrumbBanner > .customContainer > div > .title {
    font-size: 28px;
  }
  .breadcrumbBanner > .customContainer > div > .path {
    font-size: 14px;
  }
}
